import { styled } from "styled-components";

import { Container } from "style/components/Page";
import { styleHeadingXL, styleBodyLAlt } from "style/components/Typography";
import { GUTTER } from "style/grid";
import { scrollMarginTopPad } from "style/headerOffsets";
import { MQ } from "style/mediaQueries";

export const Step = styled.div`
    ${scrollMarginTopPad}
    grid-column: col-start 1 / span 12;
`;

export const FormResultContainer = styled(Container)`
    padding-bottom: 0;
    padding-left:0;
    padding-right: 0;

    ${MQ.FROM_M} {
        column-gap: ${GUTTER.MD};
        display: grid;
        grid-auto-rows: auto;
        grid-template-columns: repeat(12, [col-start] 1fr);
   }
`

export const FormResultHeading = styled.h2`
    grid-column: col-start 1 / span 12;
    ${styleHeadingXL};

    ${MQ.FROM_M} {
        grid-column: col-start 1 / span 8;
    }

    ${MQ.FROM_L} {
        grid-column: col-start 1 / span 6;
    }

    ${MQ.FROM_XL} {
        grid-column: col-start 1 / span 9;
    }
`

export const FormResultDescription = styled.p`
    ${styleBodyLAlt};
    grid-column: col-start 1 / span 4;
    margin-bottom: 0;
    margin-top: 0;

    ${MQ.FROM_M} {
        grid-column: col-start 5 / span 8;
    }

    ${MQ.FROM_L} {
        grid-column: col-start 6 / span 6;
    }

    ${MQ.FROM_XL} {
        grid-column: col-start 6 / span 6;
    }
`;

export const FormResultContent = styled.div`
    grid-column: col-start 1 / span 12;
    margin-top: 2.1875rem;

     ${MQ.FROM_M} {
        margin-top: 3.5rem;
        column-gap: ${GUTTER.MD};
        display: grid;
        grid-column: col-start 5 / span 8;
        grid-template-columns: repeat(8, [col-start] 1fr);
    }

    ${MQ.FROM_L} {
    grid-template-columns: repeat(6, [col-start] 1fr);
        grid-column: col-start 6 / span 6;
    }

    ${MQ.FROM_XL} {
        grid-column: col-start 6 / span 7;
    }
`

export const FormElements = styled.div`
    ${MQ.FROM_M} {
        grid-column: col-start 1 / span 7;
    }

    ${MQ.FROM_L} {
        grid-column: col-start 1 / span 5;
    }
`;
