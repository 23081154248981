/*
    Form functionality is documented in Azure
    https://dev.azure.com/soderbergpartner/ExternalWeb/_wiki/wikis/ExternalWeb.wiki/1722/Frontend-form-functionality
*/
import { useGTMDispatch } from "@elgorditosalsero/react-gtm-hook";
import { useAppInsightsContext } from "@microsoft/applicationinsights-react-js";
import {
    ThemeProvider as MuiThemeProvider,
    createTheme,
} from "@mui/material/styles";
import classNames from "classnames";
import React, {
    ReactChild,
    ReactElement,
    useCallback,
    useContext,
    useEffect,
    useRef,
    useState,
} from "react";
import { FormProvider, useForm, useFormState } from "react-hook-form";
import { useDispatch } from "react-redux";
import { ThemeContext, ThemeProvider } from "styled-components";

import BlockComponentSelector from "components/BlockComponentSelector";
import FormStep from "components/FormStep";
import HoneyPot from "components/HoneyPot";
import { useIsInEditMode } from "hooks";
import { default as useFormHook } from "hooks/useForm";
import { SUBMIT_FORM } from "store/actionTypes";
import FormElement from 'types/FormElement';
import { PropsWithEpiContent } from "types/Props";
import { addEditAttributes } from "utils/episerver";
import { splitStepByAccordions } from 'utils/formAccordions';
import {
    getStepComponentNames,
    getStepElements,
    scrollToFirstError,
    splitItemsByStep
} from "utils/forms";
import getComponentTypeForContent from "utils/getComponentTypeForContent";

import {
    FormElements,
    FormMessage,
    StyledForm,
    SuccessMessage,
    FormGuideHeading,
    FormGuideIntroduction,
    FormGuideHeader,
} from "./Form.styled";
import FormItemWrapper from './FormItemWrapper';
import FormProps, { formDataProps } from "./FormProps";

import FormAccordion, { ComponentName as FormAccordionComponentName } from '../FormAccordion/FormAccordion';
import FormResultStep, { ComponentName as FormResultStepComponentName } from '../FormResultStep/FormResultStep';

const Form = ({
    id,
    identifier = "form",
    action,
    spamTrapAction,
    method = "post",
    items,
    areaName,
    className,
    actionTheme = "cyan",
    gtmFormStartedEvent = "form_started",
    gtmFormSubmittedEvent = "form_submission",
    gtmFormErrorEvent = "form_error",
    gtmFormSpamTrapEvent = "form_spamtrap",
    gtmFormType = "contact",
    l18n,
    size = "medium",
    formGuide = false,
    formGuideHeading,
    formGuideIntroduction,
}: FormProps): ReactElement => {
    const methods = useForm();
    const isInEditMode = useIsInEditMode();
    const { control, handleSubmit, reset, setValue, getValues, watch } =
        methods;
    const [scrolled, setScrolled] = useState<boolean>(false);
    const [success, setSuccess] = useState<string | null>(null);
    const [gtmFormStarted, setGtmFormStarted] = useState<boolean>(false);

    const dispatch = useDispatch();
    const formState = useFormState({ control });
    const formRef = useRef<HTMLFormElement>(null);
    const appInsights = useAppInsightsContext();
    const themeContext = useContext(ThemeContext);
    const formHook = useFormHook(identifier);
    const sendDataToGTM = useGTMDispatch();
    const isSubmitting = !!formHook?.isSubmitting;
    const error = formHook?.isError ? formHook.errorMessage || null : null;
    const stepElements = getStepElements(items);
    const hasSteps = stepElements.length > 0;
    const steps = splitItemsByStep(items, hasSteps);
    const stepComponentNames = getStepComponentNames(stepElements, steps);
    const initialOpenSteps = steps
        .map((step, index) => {
            const isFormStep = step && (step[0]?.component === "FormStepBlock" || step[0]?.component === "FormResultStepBlock");
            if (!isFormStep || (isFormStep && index === 0)) return index;
        })
        .filter((x) => typeof x === "number");

    const [openSteps, setOpenSteps] = useState<(number | undefined)[]>(
        initialOpenSteps || [],
    );
    const [activeSteps, setActiveSteps] = useState<(number | undefined)[]>(
        initialOpenSteps || [],
    );
    const finishedSteps = activeSteps.slice(0, -1);

    const classes = classNames(className, { hasSteps: hasSteps });

    const handleOnSubmit = (data: formDataProps) => {
        // Check honeypot field before submitting
        if (data.hp012345 && data.hp012345 !== "") {
            setSuccess("Your submission was caught by our spam filter");
            appInsights &&
                appInsights !== null &&
                appInsights.trackEvent({
                    name: "FormSpamtrap",
                    properties: data,
                });
            sendDataToGTM({
                event: gtmFormSpamTrapEvent,
                form_type: gtmFormType,
                form_id: id,
            });
        } else {
            if (data.hp012345) delete data.hp012345;
            dispatch({
                type: SUBMIT_FORM,
                payload: {
                    path: action, // Using the real submit path and not spam path
                    id: identifier,
                    formData: data as object,
                },
            });
        }
    };

    const handleOnFocus = useCallback(() => {
        if (!gtmFormStarted) {
            setGtmFormStarted(true);
            sendDataToGTM({
                event: gtmFormStartedEvent,
                form_type: gtmFormType,
                form_id: id,
            });
        }
    }, [gtmFormStarted, gtmFormStartedEvent, gtmFormType, id, sendDataToGTM]);

    useEffect(() => {
        if (!formHook?.isSuccess) {
            setSuccess(null);
            setScrolled(false);
        } else {
            setSuccess(formHook.successMessage || null);
            if (gtmFormType === "newsletter") {
                const choiceElement =
                    items && items.length > 0
                        ? items
                              .filter(
                                  (item: PropsWithEpiContent) =>
                                      item.component === "ChoiceElementBlock",
                              )
                              .shift()
                        : undefined;
                const newsletterOptions = choiceElement?.elementName
                    ? getValues(choiceElement.elementName)
                    : "";
                sendDataToGTM({
                    event: gtmFormSubmittedEvent,
                    form_type: gtmFormType,
                    form_id: id,
                    form_newsletter_options: newsletterOptions,
                });
            } else {
                sendDataToGTM({
                    event: gtmFormSubmittedEvent,
                    form_type: gtmFormType,
                    form_id: id,
                });
            }
            reset();
        }
    }, [
        formHook?.isSuccess,
        formHook?.successMessage,
        gtmFormSubmittedEvent,
        gtmFormType,
        id,
        sendDataToGTM,
        reset,
        items,
        getValues,
    ]);

    useEffect(() => {
        if (formHook?.errorMessage) {
            sendDataToGTM({
                event: gtmFormErrorEvent,
                form_type: gtmFormType,
                form_id: id,
                error_msg: formHook.errorMessage,
            });
        }
    }, [
        formHook?.errorMessage,
        gtmFormErrorEvent,
        gtmFormType,
        id,
        sendDataToGTM,
    ]);

    useEffect(() => {
        if (!scrolled && success) {
            setScrolled(true);
            formRef.current &&
                formRef.current.scrollIntoView({
                    behavior: "smooth",
                    block: "center",
                    inline: "center",
                });
        }
    }, [scrolled, success]);

    const toggleStep = useCallback(
        (index: number) => {
            const newOpenSteps = openSteps.includes(index)
                ? openSteps.filter((item) => item !== index)
                : [...openSteps, index];
            setOpenSteps(newOpenSteps);

            if (!activeSteps.includes(index)) {
                setActiveSteps([...activeSteps, index]);
            }
        },
        [openSteps, activeSteps],
    );

    const openStep = useCallback(
        (index: number) => {
            if (!openSteps.includes(index)) setOpenSteps([index]); // Close others on open

            if (!activeSteps.includes(index))
                setActiveSteps([...activeSteps, index]);

            setTimeout(() => {
                const element = document.getElementById(`${id}-${index}`);
                if (element) {
                    element.scrollIntoView({ behavior: "smooth" });
                }
            }, 0);
        },
        [id, openSteps, activeSteps],
    );

    useEffect(() => {
        // Toggle the steps containing the errors
        if (formState.errors) {
            Object.keys(formState.errors).forEach((errorElementName) =>
                steps?.forEach((step, index) => {
                    if (
                        step.some(
                            (formItem) =>
                                formItem.elementName === errorElementName,
                        ) &&
                        !openSteps.includes(index)
                    ) {
                        openStep(index);
                    }
                }),
            );

            scrollToFirstError(formState.errors);
        }
    }, [formState.errors, openStep, openSteps, steps]);

    const mapBlockComponent = (
        item: PropsWithEpiContent,
        index: number,
        stepActive: boolean
    ) : ReactChild =>
        <BlockComponentSelector
            key={`ContentArea${item.contentId}${index}`}
            componentSelector={
                getComponentTypeForContent
            }
            content={{
                ...item,
                control:
                control,
                size: size,
                onFocus:
                handleOnFocus,
                setValue:
                setValue,
                getValues:
                getValues,
                watch: watch,
                actionTheme:
                actionTheme,
                disabled:
                    isSubmitting || !stepActive,
            }}
        />

    const mapFormElements = (
        formElement: FormElement,
        index: number,
        stepActive: boolean
    ) : ReactChild => {
        const renderAccordion = () =>
            <FormAccordion
                {...formElement.item}
            >
                {formElement.children?.map(
                    (epiContent, i) => mapBlockComponent(epiContent, i, stepActive))}
            </FormAccordion>

        const isItemAccordion = () : boolean =>
            formElement.item.component === FormAccordionComponentName;

        return isItemAccordion()
            ? renderAccordion()
            : mapBlockComponent(formElement.item, index, stepActive);
    }

    return (
        <ThemeProvider
            theme={{
                hasSteps: hasSteps,
                formGuide : formGuide
            }}
        >
            <MuiThemeProvider theme={createTheme(themeContext)}>
                <FormProvider {...methods}>
                    <StyledForm
                        ref={formRef}
                        action={spamTrapAction} // Use spam trap url here, as real submissions are handled in handleOnSubmit
                        method={method}
                        className={classes}
                        id={identifier}
                        onSubmit={handleSubmit(handleOnSubmit)}
                        {...addEditAttributes(areaName || "")}
                    >
                        {formGuide && (
                            <FormGuideHeader>
                                {(isInEditMode || formGuideHeading) && (
                                    <FormGuideHeading
                                        as="h2"
                                        {...addEditAttributes("Title")}
                                        dangerouslySetInnerHTML={{
                                            __html: formGuideHeading || "",
                                        }}
                                    />
                                )}
                                {(isInEditMode || formGuideIntroduction) && (
                                    <FormGuideIntroduction
                                        {...addEditAttributes("Description")}
                                    >
                                        {formGuideIntroduction}
                                    </FormGuideIntroduction>
                                )}
                            </FormGuideHeader>
                        )}
                        {!success && (
                            <>
                                {steps.map((formItems, index) => {
                                    const stepActive = activeSteps.includes(index);
                                    const stepOpen = openSteps.includes(index) || formGuide;

                                    const children =
                                        <FormElements
                                            key={`formItems${index}`}
                                        >
                                            {splitStepByAccordions(formItems).map((
                                                formElement: FormElement,
                                                index: number,
                                            ) : ReactChild => (
                                                <FormItemWrapper
                                                    key={`FormItemWrapper${formElement.item.contentId}${index}`}>
                                                    {mapFormElements(formElement, index, stepActive)}
                                                </FormItemWrapper>
                                            ))}
                                        </FormElements>

                                    if (stepComponentNames[index] === FormResultStepComponentName) {
                                        return stepActive ? (
                                            <FormResultStep
                                                formItems={formItems}
                                                key={`formStep${index}`}
                                                id={`${id}-${index}`}
                                                hasSteps={hasSteps}
                                                done={finishedSteps.includes(
                                                    index,
                                                )}
                                                open={stepOpen}
                                                active={stepActive}
                                                isLastStep={
                                                    index === steps.length - 1
                                                }
                                                formGuide={formGuide}
                                                toggleStep={toggleStep}
                                                openStep={openStep}
                                                stepIndex={index}
                                                l18n={l18n}
                                            >
                                                {children}
                                            </FormResultStep>
                                        ) : <></>;
                                    }

                                    return (
                                        <FormStep
                                            formItems={formItems}
                                            key={`formStep${index}`}
                                            id={`${id}-${index}`}
                                            hasSteps={hasSteps}
                                            done={finishedSteps.includes(index)}
                                            open={stepOpen}
                                            active={stepActive}
                                            isLastStep={
                                                index === steps.length - 1
                                            }
                                            stepLength={steps.length}
                                            formGuide={formGuide}
                                            toggleStep={toggleStep}
                                            openStep={openStep}
                                            stepIndex={index}
                                            l18n={l18n}
                                        >
                                            {children}
                                        </FormStep>
                                    );
                                })}
                                {error && (
                                    <FormElements key="formError">
                                        <FormMessage
                                            error={true}
                                            dangerouslySetInnerHTML={{
                                                __html: error || "",
                                            }}
                                        />
                                    </FormElements>
                                )}
                            </>
                        )}
                        {success && (
                            <SuccessMessage
                                dangerouslySetInnerHTML={{
                                    __html: success || "",
                                }}
                            />
                        )}
                        <HoneyPot control={control} id={`hp-${identifier}`} />
                    </StyledForm>
                </FormProvider>
            </MuiThemeProvider>
        </ThemeProvider>
    );
};
export default React.memo(Form);
