import loadable, { DefaultComponent } from "@loadable/component";
import React, { ReactElement } from "react";

import FormStepProps from "./FormStepProps";

const FormStep = loadable(
    (): Promise<DefaultComponent<FormStepProps>> =>
        import(/* webpackChunkName: "FormStep" */ "./FormStep"),
);
const FormStepLoader = (props: FormStepProps): ReactElement => (
    <FormStep {...props} />
);
export default FormStepLoader;
