import React, { ReactElement } from "react";

import { Container } from "./FormContent.styled";
import FormContentProps from "./FormContentProps";

import evaluteVisibilityFromDependencies from "../../utils/evaluteVisibilityFromDependencies";
import getComponentTypeForContent from "../../utils/getComponentTypeForContent";
import ContentArea from "../ContentArea/ContentAreaLoader";

export const ComponentName = "FormContentBlock";

const FormContent = ({
    blocks,
    dependencies,
    control,
}: FormContentProps): ReactElement => {
    const visibleFromDependencies =
        dependencies && control
            ? evaluteVisibilityFromDependencies(dependencies, control)
            : true;
    return (
        <>
            {visibleFromDependencies && (
                <Container>
                    <ContentArea
                        items={blocks}
                        componentSelector={getComponentTypeForContent}
                    />
                </Container>
            )}
        </>
    );
};

export default React.memo(FormContent);
