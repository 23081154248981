import loadable, { DefaultComponent } from "@loadable/component";
import React, { ReactElement } from "react";

import HoneyPotProps from "./HoneyPotProps";

const HoneyPot = loadable(
    (): Promise<DefaultComponent<HoneyPotProps>> =>
        import(/* webpackChunkName: "HoneyPot" */ "./HoneyPot"),
);
const HoneyPotLoader = (props: HoneyPotProps): ReactElement => (
    <HoneyPot {...props} />
);
export default HoneyPotLoader;
