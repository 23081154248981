import { styled } from "styled-components";
import theme from "styled-theming";

import { styleHeadingS } from "style/components/Typography";
import { MQ } from "style/mediaQueries";

export const borderColor = theme("theme", {
    black: "--line-on-image-default",
    lightgray: "--line-on-neutral-default",
    blue: "--line-on-blue-default",
    cyan: "--line-on-cyan-default",
    image: "--line-on-image-default",
});

export const Details = styled.details`
    border-bottom: 1px solid var(${borderColor});
    border-top: 1px solid var(${borderColor});
    grid-column: auto / span 2;

    .form-accordion-chevron {
        margin-right: 0.4375rem;
    }

    &[open] {
        border-bottom: none;
        .form-accordion-chevron {
            transform: rotate(90deg);
        }
    }
    ${MQ.FROM_M} {
        grid-column: auto / span 5;
    }
`

export const Summary = styled.summary`
    ${styleHeadingS};
    cursor: pointer;
    list-style: none;
    padding-bottom: 1.3125rem;
    padding-top: 1.3125rem;

    ${MQ.FROM_M} {
        padding-bottom: 1.75rem;
        padding-top: 1.75rem;
    }
`

export const Content = styled.div`
    margin-left: 2.1875rem;
`