import { FieldErrors, FieldValues } from "react-hook-form";

import { PropsWithEpiContent } from '../types/Props';

export const scrollToFirstError = (errors: FieldErrors<FieldValues>): void => {
    const firstError = Object.keys(errors).reduce(
        (field: string | undefined, a) => {
            return field && errors[field] ? field : a;
        },
        undefined,
    );
    if (firstError) {
        const elements = document.getElementsByName(firstError);
        elements &&
            elements.length > 0 &&
            elements[0].scrollIntoView({
                behavior: "smooth",
                block: "center",
                inline: "center",
            });
    }
};

export const splitItemsByStep = (
    formItems: PropsWithEpiContent[],
    hasSteps: boolean,
): PropsWithEpiContent[][] => {
    if (!hasSteps) return [formItems];

    const result: PropsWithEpiContent[][] = [[]];
    let stepNumber = 1;
    formItems.forEach((item) => {
        if (item.component === "FormStepBlock" || item.component === "FormResultStepBlock") {
            result.push([]); // If new step, create new subarray
            item.stepNumber = stepNumber++; // Assign the stepnumber
        }
        result[result.length - 1].push(item); // Push element to last array
    });

    if (result[0]?.length === 0) result.shift(); // Remove first item if empty
    return result;
};

export const getStepElements = (
    formItems: PropsWithEpiContent[]
) : PropsWithEpiContent[] => formItems.filter(
    item => item.component === "FormStepBlock" || item.component === "FormResultStepBlock"
);

export const getStepComponentNames = (
    stepElements: PropsWithEpiContent[],
    steps: PropsWithEpiContent[][],
) => {
    const defaultComponents = stepElements.map(stepElement => stepElement.component) as string[];
    if (stepElements.length === steps.length){
        return defaultComponents;
    }
    return new Array<string>("FormStepBlock" ).concat(defaultComponents)
}